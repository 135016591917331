import { getJumbotronData } from 'common/components/organisms/Jumbotron'
import { ContactUsOthers } from 'common/components/templates/ContactUsOthers'
import { getLayoutData } from 'common/utils/getLayoutData'
import React from 'react'

export interface ContactUsOthersPageProps {
    pageContext: any
}

export const ContactUsOthersPage: React.FC<ContactUsOthersPageProps> = ({
    pageContext
}) => {
    return <ContactUsOthers {...getOthersPlanPageData(pageContext)} />
}

export default ContactUsOthersPage

export const getOthersPlanPageData = (data: any) => ({
    layout: getLayoutData(data),
    jumbotron: getJumbotronData(data),
    sectionHeading: data.section_heading,
    other_query: {
        icon: data.other_query__icon,
        title: data.other_query__title,
        subs: data.other_query__subs,
        phoneNumber: data.other_query__phone_number,
        subtitle: data.other_query__subtitle,
        times: data.other_query__times,
        email: data.other_query__email,
        ctaLabel: data.other_query__cta_label,
        ctaUrl: data.other_query__cta_url,
        text: data.other_query__text
    },
    contactCards: (data.contact_cards || []).map((card: any) => ({
        title: card.title,
        icon: card.other_query__icon,
        phoneNumber: card.phoneNumber,
        subtitle: card.subtitle,
        text: card.text,
        times: card.times,
        email: card.email,
        callToAction: {
            label: card.card_cta_0396ba7__cta_label,
            variant: card.card_cta_0396ba7__cta_variant,
            url: card.card_cta_0396ba7__cta_url
        }
    }))
})
