import React from 'react'
import {
    Jumbotron,
    JumbotronProps,
} from 'common/components/organisms/Jumbotron'
import { Layout, LayoutProps } from '../organisms/Layout'
import { Text } from 'common/components/molecules/Text'
import { Box, Grid, makeStyles } from '@material-ui/core'
import { Wrapper } from '../molecules/Wrapper'
import { Spacer } from '../molecules/Spacer'
import { ContactCard } from '../molecules/ContactCard'
import { CardCtaProps } from '../molecules/CardCta'

export interface ContactUsOthersProps {
    jumbotron: JumbotronProps
    layout: LayoutProps
    sectionHeading: string
    other_query: any
    icon?: string
    contactCards: {
        callToAction: CardCtaProps
        icon?: string
        title: string
        text: string
    }[]
}

export const ContactUsOthers: React.FC<ContactUsOthersProps> = ({
    jumbotron,
    layout,
    sectionHeading,
    other_query,
    icon,
    contactCards,
}) => {
    const useStyles = makeStyles({
        sectionHeading: {
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'center',
            paddingBottom: '25px',
            fontSize: '40px',
            color: '#3A3160',
        },
        title: {
            marginBottom: '50px',
            fontSize: '32px',
            color: '#4E4670',
            lineHeight: '33px',
        },
        otherCards: {
            borderRadius: '16',
            boxShadow: '2px 0px 14px rgba(82, 97, 172, 0.08)',
            padding: '40px 24px',
            backgroundColor: '#ffffff',
        },
        icon: {
            width: '100%',
            maxWidth: '56px',
            marginRight: '5%',
        },
    })
    const classes = useStyles()
    return (
        <>
            <Layout {...layout}>
                <Jumbotron {...jumbotron} />
                {contactCards.length !== 0 && (
                    <section>
                        <Spacer variant='md' />
                        <Text
                            label={sectionHeading}
                            className={classes.sectionHeading}
                            variant='h4'
                        />
                        <Spacer variant='md' />
                        <Wrapper>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    xs={12}
                                    md={5}
                                    style={{
                                        background: '#fff',
                                        borderRadius: '16px',
                                        padding: '20px',
                                    }}
                                >
                                    <Box
                                        mt={6}
                                        width={'100%'}
                                        style={{ margin: '20px' }}
                                    >
                                        {contactCards.map((card) => (
                                            <ContactCard
                                                icon={other_query.icon}
                                                title={other_query.title}
                                                subs={other_query.subs}
                                                phoneNumber={
                                                    other_query.phoneNumber
                                                }
                                                subtitle={other_query.subtitle}
                                                times={other_query.times}
                                                email={other_query.email}
                                                ctaLabel={other_query.ctaLabel}
                                                ctaUrl={other_query.ctaUrl}
                                            />
                                        ))}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Wrapper>
                    </section>
                )}
            </Layout>
        </>
    )
}
